import MediaOverlayControlsComponent from '@wix/thunderbolt-elements/src/components/VideoBoxContainer/VideoBoxPlay/viewer/VideoBoxPlay';
import MediaOverlayControlsController from '@wix/thunderbolt-elements/src/components/VideoBoxContainer/VideoBoxPlay/viewer/VideoBoxPlay.controller';


const MediaOverlayControls = {
  component: MediaOverlayControlsComponent,
  controller: MediaOverlayControlsController
};


export const components = {
  ['MediaOverlayControls']: MediaOverlayControls
};


// temporary export
export const version = "1.0.0"
